<template>
  <v-container class="fill-height secondary un-bg" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat class="elevation-3" tile>
          <v-toolbar color="primary" dark flat>
            <v-img max-height="40" max-width="40" src="/logo.png" />
            <v-toolbar-title
              class="text-uppercase font-weight-bold subtitle-2"
              style="padding: 0 3px;"
            >
              Tracking System
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text v-if="!code">
            <p>
              Thank you <strong>{{ this.$route.params.name }}</strong> for
              signing up with us, to complete account registration please go to
              your <strong>{{ this.$route.params.email }}</strong> email inbox
              and click on confirm button.
            </p>
          </v-card-text>
          <v-card-text v-if="!!code">
            <p>
              Thank you <strong>{{ $route.params.name }}</strong> for signing up
              with us.
            </p>
            <p v-if="!invlid">
              Your email confirmation has been completed successfuly.
              Now, you will be redirected to login screen.
            </p>
            <p v-if="invlid">
              Your activatoion code is invalid, please make sure the activation
              code on the url is correct or contact system support.
            </p>
            <div class="text-center ma-5">
              <v-progress-circular
                v-if="!invlid"
                :size="40"
                color="primary"
                :indeterminate="!complete"
              ></v-progress-circular>
              <v-icon large v-if="invlid" color="red">mdi-alert-circle</v-icon>
            </div>
          </v-card-text>
          <v-card-actions v-if="complete">
            <v-spacer />
            <v-btn color="primary" tile depressed @click="goToDashboard()">
              Login
            </v-btn>
          </v-card-actions>
        </v-card>

        <div>
          <img />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      redirect: "/",
      username: null,
      email: null,
      code: null,
      processing: true,
      complete: false,
      invlid: false,
    };
  },
  mounted() {
    const { username, email, code } = this.$route.params;
    this.username = username;
    this.email = email;
    if (code) {
      this.code = code;
      this.confirmEmail(code)
        .then(() => {
          this.complete = true;
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          this.invlid = true;
        });
    }
  },
  methods: {
    ...mapActions("auth", ["login", "confirmEmail"]),

    goToDashboard() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
